import { PageProps } from 'gatsby';
import { Group } from 'packages/formidable';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Header from '~/components/Header';
import Notebook from '~/components/Notebook';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useNotes from '~/utils/useNotes';

const Thoughts: FC<EspaceProps & PageProps & UserProps> = ({
  espace,
  location,
  user,
}) => {
  const { t } = useTranslation();
  const items = useNotes({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <Header subMenu={items} title={t('notes.title')} />
      <Group
        bodyProps={{
          className: 'border-none',
        }}
        className="mt-6"
      >
        <Notebook espaceId={espace.id} />
      </Group>
    </TemplateEspace>
  );
};

export default requireEspace(Thoughts);
